import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { format } from "date-fns";

const ArtistPageTemplate = ({ data: { post, categories } }) => {
  console.log(post);
  var loop=categories.nodes;
  const featuredImage = {
    //data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    //alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Seo title={`▶️ Agenda ${post.title}`} description={`Agenda de ${post.title}. Dates i Horaris, explicació de tot l'esdeveniment, com arribar, opinions i consells per anar-hi.`} />
    <Helmet>
        <script src={'/menu.js'} />
        <link rel="icon" href={'/ico32.jpg'} sizes="32x32" />
        <link rel="icon" href={'/ico192.jpg'} sizes="192x192" />
        <link rel="apple-touch-icon" href={'/ico180.jpg'} />
        <meta name="msapplication-TileImage" content={'/ico270.jpg'} />
    </Helmet>
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

      <div class="row jumbotron p-4 p-md-5 text-white rounded bg-light">
        <div class="col-md-6 px-0">
          <div class="h1 color-dark display-4 font-italic">{parse(post.title)}</div>
          <p class="lead my-4">&nbsp;</p>
          <p class="lead mb-3">&nbsp;</p>
        </div>
        <div class="col-md-6 px-0">
            <img alt={post.altimg} title={post.altimg} src={post.img !== "" ? post.img : `/imgs/poblacions/${post.municipioslug}.jpg`} />
        </div>
      </div>
      <h1 className="h2 mt-4">Informació de {post.title} a {post.municipio}</h1>

        <table class="table table-striped">
            <tbody>
            {!!post.title && (
            <tr>
                <td>Nom de l'esdeveniment</td>
                <td>{post.title}</td>
            </tr>
            )}
            {!!post.finicio && (
            <tr>
                <td>Data d'inici</td>
                <td>{(() => {
                const fechaInicio = new Date(parseInt(post.finicio));

                if (!isNaN(fechaInicio.getTime())) {
                  const dia = fechaInicio.getDate().toString().padStart(2, '0');
                  const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                  const año = fechaInicio.getFullYear();

                  return `${dia}/${mes}/${año}`;
                } else {
                  return '';
                }
                })()}</td>
            </tr>
            )}
            {!!post.ffin && (
            <tr>
                <td>Data de finalització</td>
                <td>{(() => {
                const fechaInicio = new Date(parseInt(post.ffin));

                if (!isNaN(fechaInicio.getTime())) {
                  const dia = fechaInicio.getDate().toString().padStart(2, '0');
                  const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                  const año = fechaInicio.getFullYear();

                  return `${dia}/${mes}/${año}`;
                } else {
                  return '';
                }
                })()}</td>
            </tr>
            )}
            {!!post.lugar && (
            <tr>
                <td>Ubicació</td>
                <td>{post.lugar}</td>
            </tr>
            )}
            {!!post.direccion && (
            <tr>
                <td>Direcció</td>
                <td>{post.direccion}</td>
            </tr>
            )}
            {!!post.municipio && (
            <tr>
                <td>Municipi</td>
                <td>{post.municipio}</td>
            </tr>
            )}
            {!!post.comarca && (
            <tr>
                <td>Comarca</td>
                <td><Link to={`/comarca/${post.comarca}/`}>{post.comarca}</Link></td>
            </tr>
            )}
            </tbody>
        </table>

        {!!post.horario && (
          <section itemProp="articleBody">
            <h2 className="mt-4">Horari de {post.title}</h2>
            <div dangerouslySetInnerHTML={{__html:post.horario}} />
          </section>
        )}

        {!!post.descripcion && (
          <section itemProp="articleBody">
            <h2 className="mt-4">Informació sobre {post.title}</h2>
            <div dangerouslySetInnerHTML={{__html:post.descripcion}} />
          </section>
        )}
      </article>
    </Layout>
  )
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!,
    $wpid: Int!
  ) {
    post: mysqlFires(id: { eq: $id }) {
      id
      title
      finicio
      ffin
      horario
      lugar
      direccion
      img
      altimg
      comarca
      municipio
      descripcion
      municipioslug
    }
    categories: allMysqlFiresCategories(filter: {fira: {eq: $wpid}}) {
      nodes {
        id
      }
    }
  }
`
